.shopping-bag {
  margin-top: 50px;
  padding: 20px 15px 0; }

.heading {
  .title {
    font-size: 24px;
    font-weight: 700;
    color: #fff; }

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  a {
    color: #fff;
    border-bottom: 1px solid;
    display: block;
    line-height: 1.2;
    font-size: 12px; } }

.cart {
  background: #fff;
  border-radius: 20px;
  padding: 20px;

  .items-container {
    > div {
      border-bottom: 1px solid #dddddd;
      padding-bottom: 18px;
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between; } } }

.total-price {
  text-align: center;

  .dt {
    text-transform: none;
    font-size: 12px;
    margin-bottom: 9px; }

  .dd {
    font-size: 27px; } }

.btn-confirm-order {
  width: 100%;
  display: block;
  margin-top: 35px;
  font-size: 16px;
  padding: 15px;
  font-weight: 700;
  color: #000;
  text-align: center; }

.column {
  .title {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    margin-bottom: 10px;
    line-height: 1.4; }

  .price {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    margin-bottom: 5px; }
  &:nth-child(1) {
    max-width: 75%; }
  &:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-between; } }

.remove-item {
  margin-top: 5px;
  width: 18px;
  text-align: right;
  margin-left: auto; }
