html, body {
  padding: 0;
  margin: 0;
  background-color: #EEE; }

body {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: #444;
  background-color: #ffffff;
  max-width: 600px;
  margin: 0 auto; }

::-webkit-scrollbar {
  display: none; }

img {
  max-width: 100%;
  height: auto; }

a {
  color: inherit;
  text-decoration: none; }

* {
  box-sizing: border-box;
  outline: none !important;
  -webkit-tap-highlight-color: transparent; }

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  line-height: 1;
  * {
    display: block; } }
