.input-container {
  position: relative;
  margin-bottom: 20px;

  label {
    display: block;
    color: #000;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 8px; }

  input, textarea {
    border: 1px solid #ccc;
    background: transparent;
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    padding: 10px; }
  textarea {
    resize: none;
    height: 150px; } }

.checkbox-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse; }

.checkbox-wrapper {
  position: relative;
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0; }

  .check {
    position: absolute;
    display: inline-block;
    transform: rotate(45deg);
    height: 10px;
    width: 5px;
    border-bottom: 2px solid #ccc;
    border-right: 2px solid #ccc;
    top: 1px;
    left: 4px; } }

.checkbox-container label {
  font-weight: 400;
  line-height: 1.4;
  color: #5a5a5a; }
