.backdrop {
  background-color: rgba(0,0,0,0.75);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1; }

.modal-body {
  transition: all .4s;
  transform: translateY(-30px);
  opacity: 0;
  position: relative;
  z-index: 2;
  background-color: #FFF;
  border-radius: 15px;
  padding: 15px;
  overflow: auto;
  max-height: 100%; }

.close-modal {
  transition: all .4s;
  transform: translateY(-30px);
  opacity: 0;
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  background-color: #eee;
  right: 10px;
  top: 10px;
  background-size: 40%;
  border-radius: 10px;
  cursor: pointer;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 10px;
    height: 10px; } }


.modal {
  max-width: 600px;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  padding: 20px;
  opacity: 0;
  transition: all .4s;
  pointer-events: none;
  &.active {
    opacity: 1;
    pointer-events: all;
    .close-modal, .modal-body {
      transform: none;
      opacity: 1; } } }
