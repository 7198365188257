.bottombar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  z-index: 3;
  max-width: 600px;
  margin: 0 auto;
  a {
    width: 33.33%;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center; }
  .icon-container {
    position: relative;
    display: inline-block;
    line-height: 1.2; }
  .icon {
    display: inline-block;
    line-height: 1.2;
    img {
      width: 25px;
      line-height: 1.2; } }
  .active {
    width: 10px;
    height: 10px;
    right: -4px;
    top: 3px;
    position: absolute;
    background-color: #c80c0f;
    border-radius: 10px; } }
