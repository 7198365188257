.proposal-container {
  background: #ffffff;
  background-color: #FFF;
  border-radius: 30px;
  padding: 10px; }

.proposal-title {
  padding: 0px 0px 10px 22px;
  font-weight: 600; }

.single-proposal {
  display: flex;
  border-radius: 36px;
  padding: 10px 10px 10px 20px;
  align-items: center;
  background-color: #FAD6D0;
  margin-bottom: 5px;
  cursor: pointer;
  position: relative;
  transition: all .4s;
  &:last-child {
    margin-bottom: 0; }
  svg {
    fill: currentColor;
    width: 26px;
    height: 26px;
    display: block;
    line-height: 1; }
  span {
    color: currentColor;
    display: block;
    margin-left: 15px;
    font-size: 16px;
    font-weight: 600; }
  .arrow-right {
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border-radius: 25px;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    &::after {
      content: '';
      border: solid #7EBE00;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 3px;
      margin-left: -2px;
      transform: rotate(-45deg); } } }


.horizontal-layout {
  border-radius: 0;
  padding: 15px 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 50px;
  z-index: 9;
  max-width: 600px;
  margin: 0 auto;
  .proposal-wrapper {
    overflow: auto;
    display: flex;
    white-space: nowrap; }
  .single-proposal {
    padding: 7px 20px;
    margin: 0 7px;
    span {
      font-size: 14px; } } }

.gap {
  display: block;
  width: 7px;
  min-width: 7px;
  pointer-events: none; }
