.check-mark {
  width: 335px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  .title {
    font-size: 30px;
    color: #000;
    line-height: 1.429;
    text-align: center;
    margin-top: 15px;
    font-weight: 700; }

  .description {
    color: #666666;
    font-size: 14px;
    opacity: 1;
    line-height: 1.4;
    margin-top: 15px;
    margin-bottom: 20px; }

  img {
    width: 90px;
    display: block;
    margin: 0 auto; } }
