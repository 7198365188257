.modal-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px; }

.modal-law {
  font-size: 11px;
  color: #777;
  line-height: 14px;
  margin: 0px; }

.modal-description {
  font-size: 13px;
  line-height: 21px;
  color: #666;
  margin: 10px 0px 0px;

  p {
    margin: 0;
    padding: 0; } }

.allergens-title {
  font-size: 18px;
  margin: 20px 0px 20px;
  padding-bottom: 5px;
  border-bottom: 3px solid #000;
  font-weight: 600; }

.single-allergen {
  img {
    width: 32px;
    margin-right: 15px;
    min-width: 32px; }

  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dotted #CCC; }

.allergen-description {
  font-size: 12px;
  line-height: 18px;
  margin: 0px;
  color: #777; }

.allergen-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 6px 0; }

.single-allergen:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0; }
