.header {
  background-color: #ffffff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;
  z-index: 11;
  max-width: 600px;
  margin: 0 auto;
  img {
    max-height: 50px; } }


.languages-selector {
  line-height: 1;
  display: block;
  position: absolute;
  right: 15px;
  top: 14px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  color: #666; }



.language-active {
  cursor: pointer;
  display: flex;
  background: #eee;
  border-radius: 3px;
  padding: 5px 7px;
  align-items: center;

  img {
    width: 16px;
    margin-right: 5px; }

  span {
    margin-left: 3px;
    border: solid #000000;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg); } }

.single-language {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px dotted #CCC;
  padding-bottom: 20px;
  margin-bottom: 20px;

  svg {
    width: 24px;
    height: 20px; }

  strong {
    font-weight: 600;
    font-size: 24px;
    margin-left: 10px;
    text-transform: none;
    color: #444; }

  img {
    width: 24px;
    margin-left: auto; }

  &:last-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border: none; } }
