.heading-row {
  height: 280px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 50px; }

.body-holder {
  padding: 15px 15px 55px; }

.title-holder {
  display: block;
  width: 100%;
  color: #FFF;
  padding: 15px;
  span {
    color: #ffffff;
    opacity: .75;
    font-size: 14px;
    line-height: 24px;
    display: block; }
  h1 {
    margin: 0;
    padding: 0;
    font-size: 36px;
    line-height: 46px; } }

.description {
  color: #ffffff;
  opacity: .75;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 30px;
  p {
    margin: 0;
    padding: 0; } }
